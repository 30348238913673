.mt-10 {
    margin-top: 100px;
}

.borderLink {
    border-radius: 2px;
}

.btn_content_inside {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_content_inside img {
    width: 17.81px;
    height: 20px;
    margin-right: 8.19px;
}

.btn_content_inside div {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}

.switch_price_container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
}

.hr_divider {
    font-weight: bolder;
}

.switch_price {
    border: 2px solid rgb(239, 136, 76);
}

/* text editor */

.ql-container {
    background-color: white;
    min-height: 250px;
}

.cursor_hover {
    cursor: pointer;
}

/* image editor */
.editable_image_container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.editable_image_container img {
    width: 300px;
    height: 200px;
    object-fit: cover;
}


.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    /* background-color: red; */
}

.carousel_control {
    width: 30px;
    height: 30px;
    background-color: #424242;
    border-radius: 100%;
}

.btn_signup {
    padding: 10px 35px;
    margin-left: 10px;
    background-color: #ffffff;
    color: #6858db;
    border: 3px #6858db solid;
    display: inline-block;
    border-radius: 100px;
    -webkit-box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.1);
}

.btn_signup:hover {
    background-color: #6858db;
    color: #ffffff;
}

.margin_button_section {
    margin-bottom: 100px;
}

/* card blog main */
.main_car_blog {
    height: 440px;
}

.main_car_blog img {
    height: 300px;
    width: 100%;
    object-fit: cover;
}

.blog-content .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.max-lines-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.max-lines-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.max-lines-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* carousel */
.carousel-control-prev {
    width: fit-content;
    height: fit-content;
    margin-top: 200px;
}

.carousel-control-next {
    width: fit-content;
    height: fit-content;
    margin-top: 200px;
}

/* navbar */
.navbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.navbar-nav .nav-link {
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #56576e;
    margin: 0 15px;
}

.navbar-nav .nav-link.active {
    color: #6858db;
}

/* cards */
.card_post_container {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 40px;
}

.card_blog {
    max-width: 350px;
    height: 550px;
    box-shadow: 0 0 20px 0 #f2f2f2;
    border-radius: 6px;
}

.card_description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.description_container {
    padding: 10px 25px;
}

.card_blog img.card_image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

/* post detail */
.blog-details {}

.image_post_detail {
    width: 100%;
    height: 300px;
    object-fit: cover;

}

.blog-content-detail {
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}

.otro {
    visibility: visible;
    animation-delay: 0.4s;
}

.book {
    transition: ease 0.3s;
    margin-top: 40px;
}

.book:hover {
    background-color: #6858db;
}

.cursor_pointer {
    cursor: pointer;
}

.cursor_pointer:hover {
    color: #6858db;
}

.price_contact_container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    column-gap: 20px;
}

.text_annually {
    font-size: 13px;
    font-weight: 800;
}

.book a {
    color: white;
    text-decoration: none;
}

.book a:link {
    text-decoration: none;
}

.book a:visited {
    text-decoration: none;
}

.book a:hover {
    text-decoration: none;
}

.book a:active {
    text-decoration: none;
}

.if_video {}

.thumbnail {
    margin-top: 20px;
    border: 1px rgb(179, 179, 179) solid;
    border-radius: 5px;
    overflow: hidden;
    object-fit: cover;
    padding: 8px;
    display: flex;
    justify-content: center;
}

.thumbnail img {
    width: 100%;
}

.ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}
.ql-align-justify {
	text-align: justify;
}


@media (max-width: 500px) {
    .if_video {
        width: 350px;
        height: 200px;
    }
}


@media (max-width: 800px) {
    .if_video {
        width: 500px;
        height: 300px;
    }
}
@media (min-width: 801px) {
    .if_video {
        width: 800px;
        height: 450px;
    }
}

@media (min-width: 575px) {
    .card_blog {
        width: 350px;
        max-width: 350px;
    }
}

@media (max-width: 768px) {
    .hidden-small {
        display: none;
    }
}

@media (min-width: 768px) {
    .price-box {
        height: 700px;
    }

    .card_blog {
        width: 320px;
        max-width: 320px;
    }

    .card_post_container {
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        row-gap: 40px;
    }

    .blog-content-detail {
        position: relative;
        padding: 100px 100px 0 100px;
        background-color: white;
        width: 80%;
        border-radius: 6px;
        margin-top: -100px;
        margin-left: auto;
        margin-right: auto;
    }

}

@media (min-width: 992px) {
    .price-box {
        height: 980px;
    }

    /* .card_post_container {
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        row-gap: 40px;
    } */

    .if_video {
        width: 800px;
        height: 450px;
    }

    .carousel-inner {
        max-height: 400px;
        height: 500px;
    }

    div[class="thumbnail"] {
        max-height: 455px;
        height: 355px;
        border: 1px rgb(179, 179, 179) solid;
        border-radius: 5px;
        overflow: hidden;
        object-fit: cover;
        padding: 8px;
        display: flex;
        justify-content: center;
        /* background-color: blue; */
    }

    div[class="thumbnail"] .imageCarrusel {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
        /* background-color: blue; */
        overflow: hidden;

    }

    div[class="thumbnail"] img {
        max-height: 355px;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

}

@media (min-width: 1024px) {

    .price-box {
        height: 980px;
    }

    .card_blog {
        width: 450px;
        max-width: 450px;
    }


    .imageCarrusel-height365 {
        height: 365px;
    }

    .my_container {
        width: 100%;
        margin: 0 auto;
    }


}


@media (min-width: 1200px) {
    .price-box {
        height: 730px;
    }

    .service-box {
        height: 370px;
    }
}