.carousel {
    margin-bottom: 0;
    padding: 0 40px 30px 40px;
}

.carousel-control-prev {
	justify-content: flex-start;
}
.carousel-control-next {
	justify-content: flex-end;
}

/* The controlsy */
.carousel-control {
	left: -12px;
    height: 40px;
	width: 40px;
    background: none repeat scroll 0 0 #22233B;
    border: 4px solid #FFFFFF;
    border-radius: 23px 23px 23px 23px;
    margin-top: 180px;
}
.carousel-control.right {
	right: -12px;
}
/* The indicators */
.carousel-indicators {
	display: flex;
	bottom: -10px;
}
/* The colour of the indicators */
.carousel-indicators li {
	background: #cecece;
}
.carousel-indicators > button[type=button] {
	background: #959595;
	width: 10px;
	height: 10px;
	padding: 0;
	border: 0;
	border-radius: 100%;
}
.carousel-indicators > .active {
	background: #FF844B !important;
}

.thumbnail {
	/* border: 1px solid red !important; */
}